import {updateContent} from '../updatable-content'

export const AUTO_REFRESH_THRESHOLD = 30 * 1000 // 30 sec
const REFRESH_INCREMENT = 10 * 1000 // 10 sec
const MAX_REFRESH_COUNT = 30

let lastRefreshTime: number = Date.now()
let counter = 0

function refreshAppSection(): void {
  const appSection = document.querySelector<HTMLElement>('.js-stacks-apps-status-channel')
  if (appSection) {
    updateContent(appSection)
    updateLastRefreshTime()
  }
}

function getRefreshInterval(): number {
  // after every 10 refresh we will increase refresh interval by 10 sec.
  const delay: number = Math.floor(counter / 10) * REFRESH_INCREMENT
  return AUTO_REFRESH_THRESHOLD + delay
}

export function updateLastRefreshTime() {
  lastRefreshTime = Date.now()
}

// Checks if time elapsed since last refresh is greater then refresh intervel
// If yes then refresh the app section, increases the counter and call the function again after the refresh interval.
// else call the function after refresh interval - elapsed time.
export function checkAndRefreshAppSection() {
  const timeElapsedSinceLastRefresh = Date.now() - lastRefreshTime

  if (timeElapsedSinceLastRefresh >= getRefreshInterval()) {
    refreshAppSection()
    counter++
    if (counter <= MAX_REFRESH_COUNT) {
      setTimeout(checkAndRefreshAppSection, getRefreshInterval())
    }
  } else {
    setTimeout(checkAndRefreshAppSection, getRefreshInterval() - timeElapsedSinceLastRefresh)
  }
}

// Function to be called when the page visibility changes. This happens when user moves/comes back to the UI tab/window.
// If the user is coming back to stacks UI we will refresh the app status view.
export function handleVisibilityChange() {
  if (!document.hidden) {
    refreshAppSection()
  }
}
