import {
  AUTO_REFRESH_THRESHOLD,
  checkAndRefreshAppSection,
  handleVisibilityChange,
  updateLastRefreshTime
} from './stacks-app-refresh-handler'

import {fromEvent} from '../subscription'
import {observe} from 'selector-observer'
import {on} from 'delegated-events'
import {sendEvent} from '../hydro-analytics'

const STACKS_APP_CONFIGURE_EVENT = 'STACKS_APP_CONFIGURE_EVENT'

// Disables stack config's "Initialize Stack" button if
// 1. apps are not configured or
// 2. inputs are not valid.
function disableInitializeIfNecessary(): void {
  const initializeButton = document.querySelector<HTMLButtonElement>('.js-stacks-initialize-submit')!

  // Try to get app configure button. If this button is present then one/more apps are not configured.
  if (document.querySelector('.js-stacks-app-configure')) {
    initializeButton.disabled = true
  } else {
    const form = document.getElementById('stack_instance_repository_form')
    if (form) initializeButton.disabled = !(form as HTMLFormElement).checkValidity()
  }
}

// Listens for any change in the app's status.
observe('.js-stacks-apps-status-channel', {
  add() {
    disableInitializeIfNecessary()
  },
  subscribe: el =>
    fromEvent(el, 'socket:message', function () {
      updateLastRefreshTime()
    })
})

// send event to hydro when user clicks on Configure button.
on('click', '.js-stacks-app-configure', function (event) {
  const target = event.currentTarget
  const slug = target.getAttribute('data-app-slug')!

  const stackTemplateLink = document.getElementById('stack-template-link')
  const templateRepo = stackTemplateLink?.innerHTML

  const context = {
    app_slug: slug,
    template_repo: templateRepo || ''
  }

  sendEvent(STACKS_APP_CONFIGURE_EVENT, context)
})

function initializeConfigView(): void {
  disableInitializeIfNecessary()

  const form = document.querySelector('.js-stacks-config-form')
  form?.addEventListener('change', () => {
    disableInitializeIfNecessary()
  })

  form?.addEventListener('submit', () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange, false)
  })

  // refresh app section when user comes back to tab.
  document.addEventListener('visibilitychange', handleVisibilityChange, false)

  // refresh app section after interval
  setTimeout(checkAndRefreshAppSection, AUTO_REFRESH_THRESHOLD)
}

initializeConfigView()
